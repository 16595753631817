$(document).ready(function () {
    $('#attach_rrh_beneficiary').on('change', function () {
        let rrhSelect = $("#attach_rrh_humanResourcesReferent");

        if($(this).val() === "") {
            rrhSelect.html('');
            rrhSelect.append('<option value></option>');
        } else {
            $.ajax({
                url: "/beneficiary/" + $(this).val() + "/available/rrh",
                type: "GET",
                dataType: "JSON",
                success: function (rrhs) {

                    rrhSelect.html('');
                    rrhSelect.append('<option value></option>');

                    $.each(rrhs, function (key, rrh) {
                        rrhSelect.append('<option value="' + rrh.id + '">' + rrh.fullname + '</option>');
                    });
                }
            });
        }
    });
});
