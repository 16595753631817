$(document).ready(function($) {

    $(document).on('click', ".form-attribute-check", function(e) {
        e.preventDefault();

        let $input = $('input[data-name="'+$(this).data('name')+'"]');
        let $id = $(this).data('id');
        let $value = $input[0].value;

        let $flash = document.getElementById("flash");
        let $awarded = document.getElementById("awarded-" + $id);
        let $left = document.getElementById("left-" + $id);
        let $unionCheck = document.getElementById('unionCheck');

        let $formData = new FormData();
        let request = new XMLHttpRequest();
        request.open("GET", '/beneficiary/' + $id + '/attribute/' + $value);

        request.onload = function() {

            if (request.status === 200) {
                let $response = JSON.parse(request.response);

                $awarded.innerHTML = $response.result.numberOfAwardedChecks;
                $left.innerHTML = $response.result.numberOfChecksLeft;
                $unionCheck.innerHTML = $response.unionCheck;
                $flash.innerHTML = "<div class='alert alert-success' role='alert'>" + $response.message + "</div>";
                $input.parent().removeClass('error-input');
            } else {
                $flash.innerHTML = "<div class='alert alert-danger' role='alert'>" + request.responseText + "</div>";
                $input.parent().addClass('error-input');
            }
        };

        request.send($formData);
    });
});
