$(document).ready(function($) {

    $(document).on('click', ".clickable-element", function(e) {
        e.preventDefault();
        window.location = $(this).data("href");
    });

    $(document).on('change', ".simple-form", function(e){
        e.preventDefault();
        reloadFormResult($(this));
    });

    $(document).on('keypress', ".simple-form", function(e) {
        //Enter key
        if (e.which == 13) {
            e.preventDefault();
            reloadFormResult($(this));
        }
    });

    $(document).on('click', ".navigation", function(e) {
        e.preventDefault();

        let $path = e.target;

        if($($path).is('a')) {
            reloadFormResult($(this), $path);
        } else if($($path).is('i')) {
            reloadFormResult($(this), $(this)[0].href);
        }
    });

    $(document).on("click", ".input-clear", function (){
        let id = this.dataset.id;
        $('#' + id).val("");
        $(this).hide();

        let form = $('form[name="'+ this.dataset.form+'"]');
        reloadFormResult(form);
    });

    $(document).on('change','.input-clearable', function() {
        let span = $('span[class="input-clear"][data-id="'+this.id+'"]');
        $(span[0]).show();
    });
});

var event = require("./event.js");
var eventDotation = require("./eventDotation.js");

function reloadFormResult($this, customUrl = null) {
    let $form = $('form[name="'+$this.data('name')+'"]');

    let url = customUrl ? customUrl : $form.attr("action");
    let type = $form.attr("method");
    let data = new FormData($form[0]);
    console.log(type);

    $.ajax({
        url : url,
        type: type,
        data : data,
        contentType: false,
        cache: false,
        processData:false,
    }).done(function(response){
        $(".form-results").html(response);
        $('.change-attachment').bind('click', event.changeAttachment);
        $('.menu-contextuel-container').bind('click', event.menuContextuel);
        $('.change-dotation').bind('click', eventDotation.changeDotationStatus());
    });
}
