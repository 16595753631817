function reset() {
    $('html, body').removeClass('overflow-hidden');
    $('.popup-big-container').removeClass('active');
}

function addFileForm($collectionHolder) {
    var prototype = $collectionHolder.data('prototype');
    var index = $collectionHolder.data('index');

    var newForm = prototype;

    newForm = newForm.replace(/__name__/g, "");
    newForm = newForm.replace(/label__/g, "");

    // increase the index with one for the next item
    $collectionHolder.data('index', index + 1);

    // Display the form in the page in an li, before the "Add a tag" link li
    var $newFormDiv = $('<div></div>').append(newForm);
    $newFormDiv.append('<button type="button" class="btn-anem-small remove_file">Retirer</button>');
    $collectionHolder.append($newFormDiv);
    $('.remove_file').click(function () {
        $(this).parent().remove();
    });


}


$('#submit-dotation-button').click(function(){
    if($('#dotation_files').length > 0) {
        $('#send-dotation').trigger('click');
    } else {
        $('html, body').toggleClass('overflow-hidden');
        $('.popup-big-container').toggleClass('active');
    }
});

$("#add_file").click(function () {
    $collectionHolder = $('#dotation_files');

    // add the "add a tag" anchor and li to the tags ul


    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $collectionHolder.data('index', $collectionHolder.find(':input').length);

    addFileForm($collectionHolder);
});



$('#send-dotation').click(function () {
    reset();
});

$('#close-popup').click(function () {
    reset();
});