$(document).ready(function () {
    $('#anemref_dashboard_year').on('change', function (event) {
        if($('#anemref_dashboard_union').val() === "") {
            window.location.href = "/globaldashboard/" + $(this).val();
        } else {
            window.location.href = "/globaldashboard/OS/" + $(this).val()+"/"+ $('#anemref_dashboard_union').val();
        }
    });

    $('#anemref_dashboard_union').on('change', function (event) {
        if($(this).val() !== "") {

            window.location.href = "/globaldashboard/OS/" + $('#anemref_dashboard_year').val()+"/"+ $(this).val();
        } else {
            window.location.href = "/globaldashboard";
        }
    });

    $('#base_dashboard_year').on('change', function (e) {
        window.location.href = "/dashboard/" + $(this).val();
    });

    $('#unlockBudgetButton').click(function (evt) {
        $.ajax(
            {
                url: $(this).attr('data-target'),
                method: 'PUT'
            }
        ).done(function ()  {
            location.reload()
        })
    });

    $('.unlockBudgetButton').click(function (evt) {
        $.ajax(
            {
                url: $(this).attr('data-target'),
                method: 'PUT'
            }
        ).done(function ()  {
            location.reload()
        })
    })


});