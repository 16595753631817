$(document).ready(function () {
    var $budgetTotalPerUnionText = '#budgetAllocate-total';
    var $budgetTotal = parseFloat($($budgetTotalPerUnionText).text().split(' ').join(''));

    var $budgetPerUnionContainer = '.allocateBudget-container';
    var $budgetAllocateCheckPartText = '#budgetAllocate-checkPart';
    var $budgetAllocateAdjustementPartText = '#budgetAllocate-adjustementPart';

    var inputs = $('input[type="number"].allocateBudget');

    function calculAndWrite(container, $valInput) {
        var numberFormatter = new Intl.NumberFormat('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2});
        var $budgetAllocateCheckPart = $budgetTotal * ($valInput / 100);
        container.find($budgetAllocateCheckPartText).text(numberFormatter.format($budgetAllocateCheckPart));

        var $budgetAllocateAdjutement = ($budgetTotal * ($valInput / 100));
        container.find($budgetAllocateAdjustementPartText).text(numberFormatter.format(($budgetAllocateAdjutement)));
    }

    inputs.on('input', function () {
        var totalPart = 0;
        inputs.each(function () {
            var $valInput = parseFloat($(this).val());
            var container = $(this).parents($budgetPerUnionContainer);
            totalPart += $valInput;

            if ($.isNumeric($valInput) && !($valInput > 100) && !($valInput < 0)){
                calculAndWrite(container, $valInput);
            }else{
                container.find($budgetAllocateCheckPartText + ', ' + $budgetAllocateAdjustementPartText).text('0');
            }
        });
    });

    inputs.on('change', function () {
        var $thisElement = $(this);
        var $value = parseFloat($thisElement.val());
        var $elementId = $thisElement.attr('id');

        var idTextCheckPart = 'budget_per_union_adjustement_adjustementCheckPart';
        var idTextDotationPart = 'budget_per_union_adjustement_adjustementDotationPart';

        var idCheckPart = $('#' + idTextCheckPart);
        var idDotationPart = $('#' + idTextDotationPart);

        if ($value > 100 || $value < 0) {
            idDotationPart.val(0);
            idCheckPart.val(0);
            $($budgetAllocateCheckPartText + ', ' + $budgetAllocateAdjustementPartText).text('0');
        }else {
            var $valueInverse = 100 - $value;

            if ($elementId === idTextCheckPart) {
                idDotationPart.val($valueInverse);
                calculAndWrite(idDotationPart.parents($budgetPerUnionContainer), $valueInverse);
            }else if ($elementId === idTextDotationPart) {
                idCheckPart.val($valueInverse);
                calculAndWrite(idCheckPart.parents($budgetPerUnionContainer), $valueInverse);
            }
        }



    });

    $('#modify-repartition').click(function () {
        $('input[type="number"]').prop('readonly',false);
        $(this).hide();
        $('#validate-budget').show();

    })

});