$('document').ready(function () {
    let $sendingDateInput = $('#dotation_sendingDate');
    let $startInput = $('#dotation_startDate');
    let $endInput = $('#dotation_endDate');
    $sendingDateInput.datepicker();

    var from = $startInput.datepicker({
        'minDate': null,
        onSelect: function (dateText, inst) {
            to.datepicker( 'option', 'minDate', dateText);
        }
    });
    var to = $endInput.datepicker({
        'minDate': null,
        onSelect: function (dateText, inst) {
            from.datepicker('option', 'maxDate', dateText);
        }
    });
    $.datepicker.setDefaults($.datepicker.regional[ "fr" ]);

    $('#dotation_files').on("change", function(){
        var dotationFiles = $("#dotation_files_change");
        var dotationFilesBase = $("#dotation_files_change .base");
        var dotationFilesJs = $("#dotation_files_change .text-js");

        var files = $(this).prop("files");
        var names = $.map(files, function(val) { return val.name; });
        var valeur = names.join(', ');

        if (names.length) {
            dotationFilesBase.hide();
            dotationFilesJs.show();
            dotationFilesJs.text(valeur);
        } else {
            dotationFilesBase.show();
            dotationFilesJs.hide();
        }
    });

});