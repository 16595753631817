const $ = require('jquery');
global.$ = $;

var event = require("./event.js");

require('bootstrap');
require('../js/mask.js');
require('../js/redirect.js');
require('../js/attributeCheck.js');
require('../js/switchActivated.js');
require('../js/controlAttachRRH.js');
require('../js/controlAskAbsence.js');
require('../js/controlCreateRefund.js');
require('../js/controlValidateRefund.js');
require('../js/select2.min.js');
require('../js/chartjs.bundle.min.js');
require('../js/chartjs-plugin-labels.min.js');
require('../js/distributionBudget.js');
require('../js/allocateBudget.js');
require('../js/jquery-ui.js');
require('../js/translations/datepicker.fr.min.js');
require('../js/controlNumberChecks');
require('../js/dotation');
require('../js/manageDotationStatus');
require('../js/notificationManager');
require('../js/askDotation');
require('../js/dashboard');
require('../js/resumeDotation');
require('../js/controlEditAbsence');
$(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
        $('body').toggleClass('active-sidebar-mobile');
    });

    $('.menu-contextuel-container').bind('click', event.menuContextuel);

    $('select:not(:hidden)').select2({
        minimumResultsForSearch: -1
    });

    $('#research_dotation_year').select2({
        minimumResultsForSearch: -1,
        placeholder: " ",
        allowClear: false,
    });

    $('#research_dotation_union').select2({
        minimumResultsForSearch: -1,
        placeholder: " ",
        allowClear: true,
    });


    $('#validate_refund_datePayment').datepicker();
    $.datepicker.setDefaults($.datepicker.regional[ "fr" ]);


});
