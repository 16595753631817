$(document).ready(function () {

    let $reasonRefused = $('#reasonRefused');
    let $referencePayment = $('#referencePayment');
    let $datePayment = $('#datePayment');
    let $refundRib = $('#refundRib');

    $('#validate_refund_validate_0').on('click', function () {

        if (!$reasonRefused[0].hasAttribute('hidden')) {
            $reasonRefused.attr('hidden',true);
            $referencePayment[0].removeAttribute('hidden');
            $datePayment[0].removeAttribute('hidden');
            $refundRib[0].style.display = 'block';
        }
    });

    $('#validate_refund_validate_1').on('click', function () {
        if ($reasonRefused[0].hasAttribute('hidden')) {
            $reasonRefused[0].removeAttribute('hidden');
            $referencePayment.attr('hidden', true);
            $datePayment.attr('hidden', true);
            $refundRib[0].style.display = 'none';
        }
    });

    $('form[name="validate_refund"]').on('keyup keypress', function(e) {
        let keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });
});