$(document).ready(function () {

    $('#create_refund_salary').keypress(function (e) {
        if (e.key == "."){
            e.preventDefault();
        }
    });

    $('#create_refund_salary').on('input', function () {
        let $amountField = $('#create_refund_amount');
        let $amoutDiv = $('#amountDiv');
        let $halfDay = parseInt($('#halfDay').text());


        if ($.isNumeric($(this).val()) && $.isNumeric($halfDay)){
            let $amount = ($(this).val() * $halfDay).toFixed(2);
            $amountField.val($amount);
            console.log($('#create_refund_amount').val().length + 1);
            $('#create_refund_amount').width((($('#create_refund_amount').val().length + 1) * 8) - 6);
            $amoutDiv.removeClass('d-none');
            $amountField.removeAttr('hidden');
        }else{
            $amoutDiv.addClass('d-none');
            $amountField.attr('hidden');
        }
    });

});