$(document).ready(function () {

    var $checkPart = parseFloat($('#budget-checksPart-percent').text());
    var $ajustementPart = parseFloat($('#budget-ajustementPart-percent').text());
    var $dotationPart = parseFloat($('#budget-dotationPart-percent').text());

    var $budgetPerUnionContainer = '.budgetPerUnion-container';
    var $budgetTotalPerUnionText = '#budgetDistribution-total';
    var $budgetTotal = parseInt($($budgetTotalPerUnionText).text().split(' ').join(''));
    var $budgetAllocateCheckPartText = '#budgetDistribution-checkPart';
    var $budgetAllocateDotationPartText = '#budgetDistribution-dotationPart';
    var $budgetAllocateAjustementPartText = '#budgetDistribution-ajustementPart';

    var inputs = $('input[type="number"].distributionBudget');

    function numberWithSpace(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    var sumBudget = [];

    function calculAndWrite(container, $valInput) {
        var numberFormatter = new Intl.NumberFormat('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':2});
        var numberFormatterFour = new Intl.NumberFormat('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':4});
        //budget.amount * 0.19 * budgetPerUnion.vars.value.part / 100
        var $budgetTotalPerUnion = $budgetTotal * 0.19 * ($valInput / 100);
        container.find($budgetTotalPerUnionText).text(numberFormatter.format($budgetTotalPerUnion));
        sumBudget.push($budgetTotalPerUnion);

        var $budgetAllocateCheckPart = ($budgetTotal * ($checkPart/100)* $valInput / 100);
        container.find($budgetAllocateCheckPartText).text(numberFormatter.format($budgetAllocateCheckPart));

        //(budget.amount * (budget.dotationPart/100)* budgetPerUnion.vars.value.part / 100)
        var $budgetAllocateDotationPart = ($budgetTotal * ($dotationPart/100)* $valInput / 100);
        container.find($budgetAllocateDotationPartText).text(numberFormatter.format($budgetAllocateDotationPart));

        //(budget.amount * (budget.ajustementPart/100 )* budgetPerUnion.vars.value.part / 100)
        var $budgetAllocateAjustementPart = ($budgetTotal * ($ajustementPart/100 )* $valInput / 100);
        container.find($budgetAllocateAjustementPartText).text(numberFormatter.format($budgetAllocateAjustementPart));
    }

    inputs.on('input', function () {
        var totalPart = 0;
        inputs.each(function () {
            var $valInput = parseFloat($(this).val());
            var container = $(this).parents($budgetPerUnionContainer);
            totalPart += $valInput;

            if ($.isNumeric($valInput) && !($valInput > 100) && !($valInput < 0)){
                calculAndWrite(container, $valInput);
            }else{
                container.find($budgetTotalPerUnionText + ', ' + $budgetAllocateCheckPartText + ', ' + $budgetAllocateDotationPartText + ', ' + $budgetAllocateAjustementPartText).text('0');
            }
        });

        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        var numberFormatter = new Intl.NumberFormat('fr-FR', {'minimumFractionDigits':2,'maximumFractionDigits':4});
        $('#budget-secondstep-total').text(numberFormatter.format(sumBudget.reduce(reducer)));
        sumBudget = [];
    });

    inputs.on('change', function () {
        var values = inputs.map(function(){
            return $(this).val();
        }).get();

        var nbrZero = 0;
        var totalPart = 0;
        $.each(values, function (key, value) {
            if (value === "0" || value === ""){
                nbrZero += 1;
                values[key] = "0";
                value = "0"
            }
            totalPart += parseFloat(value);
        });

        if (nbrZero === 1) {
            var position = $.inArray("0", values);
            var calcul = 100 - totalPart;
            var inputFocus = inputs.eq(position);
            var numberFormatter = new Intl.NumberFormat('en-EN', {'minimumFractionDigits':2,'maximumFractionDigits':4})
            inputFocus.val(numberFormatter.format(calcul));
            calculAndWrite(inputFocus.parents($budgetPerUnionContainer), numberFormatter.format(calcul));

            var total = 0;
            $('.budgetDistribution-total').map(function(){
                total += parseFloat(($(this).text()).split(",")[0].replace(/\s/g, ''));
            }).get();
            $('#budget-secondstep-total').text(numberFormatter.format(total));
        }
    });


});