require('jquery-mask-plugin');

$(document).ready(function () {
    $('.phone-fr').mask('00 00 00 00 00');

    $('.two-digit-max').mask('00');

    $('.zip-code').mask('00000');

    $('.iban-fr').mask('FR00 0000 0000 0000 0000 0000 000', {
        placeholder: '____ ____ ____ ____ ____ ____ ___'
    });

    $('.bic-fr').mask('AAAA AA ZZ ZZZ', {
        placeholder: '____ __ __ ___',
        'translation': {
            A: {pattern: /[A-Z]/},
            Z: {pattern: /[A-Z-0-9]/},
        }
    });
});

