const $ = require('jquery');
global.$ = $;

function changeAttachment() {
    let $this = $(this);
    let $url = $this.attr("data-target");
    let dataId = $this.data('id');
    $.post($url, function (data) {

    }).done(function (response){
        let containerStatus = $('.attachement-status[data-id="' + dataId + '"]');
        let containerMenu = containerStatus.siblings('.menu-contextuel-hover').find('.menu-contextuel-container');
        let containerButtonDeny = $('#context-deny.change-attachment[data-id="' + dataId + '"]');
        let containerButtonAccept = $('#context-accept.change-attachment[data-id="' + dataId + '"]');

        if (response == 1) {
            containerStatus.html('<span class="statut-refuser">Refusé</span>');
            containerButtonAccept.show();
            containerMenu.show();
            containerButtonDeny.hide();
        } else if(response == 0) {
            containerStatus.html('<span class="statut-accepter">Validé</span>');
            containerButtonAccept.hide();
            containerButtonDeny.show();
            containerMenu.show();
        }
    });
}

function menuContextuel() {
    let $this = $(this);
    $('.menu-contextuel-container').removeClass('active');
    $this.children('.menu-contextuel').addClass('active');
    $('.menu-contextuel-hover').on('mouseleave', function () {
        $('.menu-contextuel').removeClass('active');
    });
}

module.exports = {
    changeAttachment,
    menuContextuel,
};
