$(document).ready(function ($) {
    $('.notification').click(function (event) {
        $.ajax(
            {
                url: '/notification/read/'+$(this).attr('id'),
                method: 'PUT',
                success: function (response, status) {
                    $('#'+response).remove();
                    var notificationNumber = $('#notification-center').children().length;
                    $('#notification-number').text(notificationNumber);
                }

            })

    });

});