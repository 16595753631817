$(document).ready(function () {

    $('form .input-container input:hidden,form .input-container select:hidden').parents('.col-xs-12').addClass('d-none');

    //////////////////////////////////////////////////

    let $startInput = $('#edit_absence_start');
    let $startScheduleInput = $('#edit_absence_startSchedule');
    let $endInput = $('#edit_absence_end');
    let $endScheduleInput = $('#edit_absence_endSchedule');
    let $confirmMessage = $('#confirm-exception-message');
    let $exception = $('#edit_absence_confirmException');
    let $labelException = $('#label-confirm-exception-message');
    let $label = $("label[for='" + $exception.attr('id') + "']");
    let $enterpriseInput = $('#edit_absence_enterprise');
    let $beneficiaryInput = $('#edit_absence_beneficiary');

    if ($startInput.length){
        if($startInput.val() !== '') {
            var dateText = $startInput.val();
            var dateParts = dateText.split("/");
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            processDayDiff(dateObject, $confirmMessage, $exception, $label, $labelException);
            getHalfDays($startInput, $endInput)
        }
    }


    $enterpriseInput.on('change', function () {
        let beneficiarySelect = $("#edit_absence_beneficiary");
        let enterpriseName = $("#absence_enterprise_name");
        let firstName = $("#absence_firstname");
        let lastName = $("#absence_lastname");
        let unionChecks = $("#absence_union_checks")

        if($(this).val() === "") {
            beneficiarySelect.html('');
            beneficiarySelect.append('<option value>Bénéficiaire</option>');
            enterpriseName.html('---');
            unionChecks.html('---');
            firstName.html('---');
            lastName.html('---');
        } else if(!$enterpriseInput.is(":hidden")) {
            enterpriseName.html($('#edit_absence_enterprise option:selected').text());
            $.ajax({
                url: "/enterprise/" + $(this).val() + "/available/beneficiaries",
                type: "GET",
                dataType: "JSON",
                success: function (beneficiaries) {

                    beneficiarySelect.html('');
                    beneficiarySelect.append('<option value>Bénéficiaire</option>');

                    $.each(beneficiaries, function (key, beneficiary) {
                        beneficiarySelect.append('<option value="' + beneficiary.id + '" data-firstname="'+ beneficiary.firstname + '" data-lastname="'+ beneficiary.lastname +'" data-check="'+ beneficiary.numberOfChecksLeft +'">' + beneficiary.fullname + '</option>');
                    });
                }
            });
        }
    });

    $enterpriseInput.trigger('change');

    $beneficiaryInput.on('change', function() {
        let firstName = $("#absence_firstname");
        let lastName = $("#absence_lastname");
        let unionChecks = $("#absence_union_checks");
        let optionSelected = $('#edit_absence_beneficiary option:selected');

        firstName.html(optionSelected.data('firstname'));
        lastName.html(optionSelected.data('lastname'));
        unionChecks.html(optionSelected.data('check'));
    });

    $startInput.on('change', function () {
        getHalfDays($(this), $endInput)
    });

    $startScheduleInput.on('change', function () {
        getHalfDays($startInput, $endInput)
    });

    $endInput.on('change', function () {
        getHalfDays($startInput, $(this))
    });

    $endScheduleInput.on('change', function () {
        getHalfDays($startInput, $endInput)
    });

    var from = $startInput.datepicker({
        onSelect: function (dateText, inst) {
            var dateParts = dateText.split("/");
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            processDayDiff(dateObject, $confirmMessage, $exception, $label, $labelException);
            to.datepicker( 'option', 'minDate', dateText);
            getHalfDays($startInput, $endInput)
        }
    });
    var to = $endInput.datepicker({
        onSelect: function (dateText, inst) {
            from.datepicker('option', 'maxDate', dateText);
            getHalfDays($startInput, $endInput)
        }
    });
    $.datepicker.setDefaults($.datepicker.regional[ "fr" ]);

    $('#resetAbsenceForm').click(function (event) {
        event.preventDefault();
        from.val('');
        to.val('');
        $labelException.prop('hidden',true);
        $confirmMessage.prop('hidden',true);
        from.datepicker('option', 'minDate', 0);
        from.datepicker('option', 'maxDate', null);
        to.datepicker('option', 'minDate', 0);
        to.datepicker('option', 'maxDate', null);
    })

});

function processDayDiff(startInput, confirmMessage, exception, label, labelException) {
    let $now = new Date();
    let $date = startInput;

    $now.setHours(0, 0,0,0);
    $date.setHours(0, 0,0,0);

    let $diffDays = dayDiff($now, $date);

    if($diffDays <= 8) {
        confirmMessage.removeAttr('hidden');
        exception.removeAttr('hidden');
        label.removeAttr('hidden');
        labelException.removeAttr('hidden');
    } else {
        confirmMessage.attr('hidden',true);
        exception.attr('hidden',true);
        label.attr('hidden',true);
        labelException.attr('hidden',true);
    }
}

function dayDiff(d1, d2)
{
    d1 = d1.getTime() / 86400000;
    d2 = d2.getTime() / 86400000;
    return Math.round((d2 - d1));
}

function getHalfDays(start, end) {
    let startValue = start.val();
    let startScheduleValue = $('#edit_absence_startSchedule option:selected').val();
    let endValue = end.val();
    let endScheduleValue = $('#edit_absence_endSchedule option:selected').val();
    let halfDays = $('#absence_halfdays')
    $.ajax({
        url: "/ajax/halfdays",
        type: "POST",
        dataType: 'JSON',
        data: {start: startValue, startSchedule: startScheduleValue, end: endValue, endSchedule: endScheduleValue},
        success: function (value) {
            halfDays.html(value);
        },
        error: function () {
            halfDays.html('---');
        }
    })
}