const $ = require('jquery');
global.$ = $;

function changeDotationStatus() {
    $('.dotation-button.btn-statut-accepter').click(function () {
        var target = $(this).attr('data-target');
        var dataId = $(this).data('id');
        ajaxDotation(target, dataId);
    });

    var $select = $('select#motif').select2({
        minimumResultsForSearch: -1
    });

    $('.dotation-button.btn-statut-refuser').click(function () {
        var $id = $(this).data('id');

        $('html, body').toggleClass('overflow-hidden');
        $('.popup-big-container').toggleClass('active');

        $('select#motif option').data('id', $id);

        $('select#motif').on('change', function () {
            var element = $(this).find('option:selected');
            var link = element.val() + $id;
            $('.btn-statut-accepter.dotation-button-refuser-popup').data('target', link);
            $('.btn-statut-accepter.dotation-button-refuser-popup').data('id', $id);
        });

        $('.btn-statut-accepter.dotation-button-refuser-popup').click(function () {
            var target = $(this).data('target');
            if (target !== '') {
                ajaxDotation(target, $id);
            }
        });

        $('.btn-statut-refuser.go-back').click(function () {
            reset();
        });
    });

}

function ajaxDotation(target, dataId) {
    $.ajax({
        url: target,
        type: 'PUT',
    }).done(function (response) {
        var containerStatus = $('.dotation-status[data-id="' + dataId + '"]');

        if (response == 3) {
            containerStatus.html(' <span class="statut-refuser">Justificatif(s) manquant(s)</span>');
            reset();
        }  else if (response == 2) {
            containerStatus.html(' <span class="statut-refuser">Hors usages</span>');
            reset();
        }
        else if(response == 4) {
            containerStatus.html('<span class="statut-accepter">Validé</span>');
        }
    });
}

function reset() {
    $('select#motif option').data('id', '');
    $('html, body').removeClass('overflow-hidden');
    $('.popup-big-container').removeClass('active');
    $('.btn-statut-accepter.dotation-button-refuser-popup').data('target', '');
    $('.btn-statut-accepter.dotation-button-refuser-popup').data('id', '');
    $("select#motif").val($("select#motif option:first").val());
    $('.btn-statut-accepter.dotation-button-refuser-popup').unbind('click');
}

module.exports = {
    changeDotationStatus,
    ajaxDotation,
    reset,

};