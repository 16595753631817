$(document).ready(function($) {

    $(document).on('change', "[id^=switch-activated-]", function(e) {
        e.preventDefault();

        let $id = $(this).data('id');

        let $formData = new FormData();
        let request = new XMLHttpRequest();
        request.open("GET", '/user/' + $id + '/activated');
        request.send($formData);
    });
});
